import React, { useEffect } from "react";
import Modal from "react-modal";

const getModalWidth = () => {
  if (window.innerWidth >= 768) {
    return "50%";
  } else {
    return "90%";
  }
};

const AreYouShure = ({ isOpen, onRequestClose, contentLabel, children }) => {
  useEffect(() => {
    const updateWidth = () => {
      const modalContent = document.querySelector(
        ".ReactModal__Content"
      ) as HTMLElement;
      if (modalContent) {
        modalContent.style.width = getModalWidth();
      }
    };

    window.addEventListener("resize", updateWidth);

    // Initial call to set width
    if (isOpen) {
      updateWidth();
    }

    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={contentLabel}
      style={{
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "#401a6e",
          width: getModalWidth(),
          margin: 0,
          padding: 0,
        },
        overlay: {
          background: "rgba(11, 2, 18, 0.75)",
        },
      }}
      ariaHideApp={false}
    >
      <div>{children}</div>
    </Modal>
  );
};

export default AreYouShure;
