import React, { useEffect, useState } from "react";
import DayPicker from "../DatePicker/DayPicker";
import Cookies from "js-cookie";
import EditOffers from "./EditOffer";

interface OffersData {
  pay_amount: number;
  receive_amount: number;
  offer_type: string;
  offer_name: string;
  active_from: Date;
  active_to: Date;
  currency: string;
  id: number;
}

export default function Offers() {
  const date = new Date();
  const [payAmount, setPayAmount] = useState<string | number>("");
  const [receiveAmount, setReceiveAmount] = useState<string | number>("");
  const [offerName, setOfferName] = useState<string>("");
  const [activeFrom, setActiveFrom] = useState<Date | null>(date);
  const [activeTo, setActiveTo] = useState<Date | null>(date);
  const [currency, setCurrency] = useState("");
  const [offerType, setOfferType] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [offersData, setOffersData] = useState<OffersData[]>([]);
  const [edit, setEdit] = useState<number | null>(null);
  const [typeFilters, setTypeFilters] = useState("");

  async function handleSumbit() {
    const session_token = Cookies.get("c8_session_token");
    try {
      const res = await fetch(`${process.env.API_URL}offers`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.API_KEY as string,
        },
        body: JSON.stringify({
          usersession: session_token,
          pay_amount: payAmount,
          receive_amount: receiveAmount,
          offer_type: offerType,
          offer_name: offerName,
          activeFrom: activeFrom,
          activeTo: activeTo,
          currency: currency,
        }),
      });
      if (res.ok) {
        window.location.reload();
        const data = await res.json();
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchOffers() {
    try {
      const res = await fetch(
        `${process.env.API_URL}offers?offer_type=${typeFilters}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.API_KEY as string,
          },
        }
      );
      const data = await res.json();
      setOffersData(data);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchOffers();
  }, [typeFilters]);

  useEffect(() => {
    if (edit !== null && offersData[edit]) {
      const selectedOffer = offersData[edit];
      setOfferName(selectedOffer.offer_name || "");
      setPayAmount(selectedOffer.pay_amount || "");
      setReceiveAmount(selectedOffer.receive_amount || "");
      setCurrency(selectedOffer.currency || "");
      setOfferType(selectedOffer.offer_type || "");
      setActiveFrom(selectedOffer.active_from);
      setActiveTo(selectedOffer.active_to);
    }
  }, [edit]);

  return (
    <div id="content" className="p-4 content-t">
      <div className="flex flex-row">
        {!showForm ? (
          <div>
            <div className="flex flex-col gap-2  lg:flex lg:flex-row lg:justify-between">
              <div>
                <button
                  className="bg-lime flex justify-center items-center font-anton
          hover:bg-limehover text-main w-[180px] h-[32px] text-center cursor-pointer"
                  onClick={() => setShowForm(true)}
                >
                  Create Offer
                </button>
              </div>
              <div className="flex gap-2">
                <button
                  className=" px-2 h-[32px] bg-white text-main text-[14px] font-chakra leading-[15px] 
                  flex items-center justify-center cursor-pointer border border-main"
                  onClick={() => setTypeFilters("hard")}
                >
                  Hard Offers
                </button>
                <button
                  className=" px-2 h-[32px] bg-white text-main text-[14px] font-chakra leading-[15px] 
                  flex items-center justify-center cursor-pointer border border-main"
                  onClick={() => setTypeFilters("soft")}
                >
                  Soft Offers
                </button>
                <button
                  className=" px-2 h-[32px] bg-white text-main text-[14px] font-chakra leading-[15px] 
                  flex items-center justify-center cursor-pointer border border-main"
                  onClick={() => setTypeFilters("")}
                >
                  Clear Filters
                </button>
              </div>
            </div>
            <div>
              <div className="grid grid-cols-1 gap-2 mt-2 font-chakra uppercase text-white">
                <div className="grid grid-cols-1 lg:grid lg:grid-cols-2 gap-4">
                  {offersData?.map((offer: OffersData, index) => {
                    return (
                      <div key={index}>
                        {edit === index ? (
                          <div style={{ border: "2px solid white" }}>
                            <h1 className="text-white text-[25px] font-anton px-2">
                              Edit Offer
                            </h1>

                            <>
                              <EditOffers
                                offerName={offerName}
                                payAmount={payAmount}
                                receiveAmount={receiveAmount}
                                offerType={offerType}
                                activeFrom={activeFrom}
                                activeTo={activeTo}
                                currency={currency}
                                setOfferName={setOfferName}
                                setOfferType={setOfferType}
                                setPayAmount={setPayAmount}
                                setReceiveAmount={setReceiveAmount}
                                setActiveFrom={setActiveFrom}
                                setActiveTo={setActiveTo}
                                setCurrency={setCurrency}
                                offerId={offer.id}
                                setEdit={setEdit}
                              />
                            </>
                          </div>
                        ) : (
                          <div
                            className="grid grid-cols-3 gap-4 p-2 w-full h-[285px]"
                            style={{ border: "2px solid white" }}
                          >
                            <div
                              key={index}
                              className="flex flex-col justify-between w-full p-2"
                            >
                              <div className="w-[250px]">
                                <p className="text-[16px] font-chakra">
                                  <strong>Offer Name:</strong>{" "}
                                  {offer.offer_name}
                                </p>
                                <p className="text-[16px] font-chakra">
                                  <strong>Offer Type:</strong>{" "}
                                  {offer.offer_type}
                                </p>
                                <p className="text-[16px] font-chakra">
                                  <strong>Pay Amount:</strong>{" "}
                                  {offer.pay_amount}
                                </p>
                                <p className="text-[16px] font-chakra">
                                  <strong>Receive Amount:</strong>{" "}
                                  {offer.receive_amount}
                                </p>
                                <p className="text-[16px] font-chakra">
                                  <strong>Currency:</strong> {offer.currency}
                                </p>{" "}
                              </div>
                              <div>
                                <button
                                  className="bg-lime font-anton text-[14px] flex justify-center items-center hover:bg-limehover 
                                  text-main w-[100px] h-[32px] text-center cursor-pointer border-none"
                                  onClick={() => setEdit(index)}
                                >
                                  Edit
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      {showForm && (
        <div>
          <h1 className="text-white text-[25px]">Create Offer</h1>
          <div className="lg:grid lg:grid-cols-2 gap-2 mt-4  p-6 font-chakra uppercase text-white">
            <div>
              <label
                className="font-chakra w-[150px] text-right text-[14px]"
                htmlFor="name"
              >
                Offer Name:
              </label>
              <div>
                <input
                  type="text"
                  id="name"
                  placeholder="Offer Name"
                  onChange={(e) => setOfferName(e.target.value)}
                  className="text-main w-[150px] font-chakra h-[30px] w-full"
                />
              </div>
            </div>
            <div>
              <label
                className="font-chakra text-start text-[14px]"
                htmlFor="pay"
              >
                Pay Amount:
              </label>
              <div>
                <input
                  type="number"
                  id="pay"
                  placeholder="Pay Amount"
                  onChange={(e) => setPayAmount(e.target.value)}
                  className="text-main font-chakra h-[30px] w-full"
                />
              </div>
            </div>

            <div>
              <label
                className="font-chakra w-[150px] text-right text-[14px]"
                htmlFor="receive"
              >
                Receive Amount:
              </label>
              <div>
                <input
                  type="number"
                  id="receive"
                  placeholder="Receive Amount"
                  onChange={(e) => setReceiveAmount(e.target.value)}
                  className="text-main font-chakra h-[30px] w-full"
                />
              </div>
            </div>

            <div className="w-full flex flex-col lg:flex-row  gap-2">
              <div className="w-[10em]">
                <label
                  className="font-chakra text-start text-[14px]"
                  htmlFor="from"
                >
                  Active From:
                </label>
                <DayPicker
                  startDate={activeFrom}
                  setStartDate={setActiveFrom}
                  setEndDate={setActiveFrom}
                  className={"h-[30px] drop-down-font w-full"}
                />
              </div>

              <div className="w-[10em]">
                <label className="font-chakra text-[14px]" htmlFor="to">
                  Active To:
                </label>
                <DayPicker
                  startDate={activeTo}
                  setEndDate={setActiveTo}
                  setStartDate={setActiveTo}
                  className={"h-[30px] drop-down-font w-full"}
                />
              </div>

              <div className="w-[9.15em]">
                <label className="font-chakra text-[14px]" htmlFor="currency">
                  Currency:
                </label>
                <div>
                  <select
                    className="h-[30px] text-main w-full"
                    id="currency"
                    onChange={(e) => setCurrency(e.target.value)}
                  >
                    <option value=""></option>
                    <option value="INR">INR</option>
                    <option value="CR8_RBY">CR8_RBY</option>
                  </select>
                </div>
              </div>

              <div className="w-[9.15em]">
                <label className="font-chakra text-[14px]" htmlFor="offers">
                  Offer Type:
                </label>
                <div>
                  <select
                    className="h-[30px] text-main w-full"
                    id="offers"
                    onChange={(e) => setOfferType(e.target.value)}
                  >
                    <option value=""></option>
                    <option value="hard">HARD</option>
                    <option value="soft">SOFT</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="flex items-center gap-2 mt-4">
              <button
                className="bg-lime flex justify-center items-center font-anton
              hover:bg-limehover text-main w-[180px] h-[32px] text-center cursor-pointer"
                type="submit"
                onClick={() => handleSumbit()}
              >
                Create Offer
              </button>

              <button
                className="border-2 flex justify-center items-center font-anton
              border-lime hover:border-limehover border-solid text-lime hover:text-limehover 
              w-[180px] h-[32px] text-center cursor-pointer"
                onClick={() => setShowForm(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
