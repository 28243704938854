import React, { useEffect, useState } from "react";
import DayPicker from "../DatePicker/DayPicker";
import AreYouShure from "../Modals/AreYouShureModal";
import Cookies from "js-cookie";

interface EditProps {
  offerName: string;
  offerType: string;
  payAmount: string | number;
  receiveAmount: string | number;
  activeFrom: Date | null;
  activeTo: Date | null;
  currency: string;
  setOfferName: (value: string) => void;
  setOfferType: (value: string) => void;
  setPayAmount: (value: string) => void;
  setReceiveAmount: (value: string) => void;
  setActiveFrom: React.Dispatch<React.SetStateAction<Date | null>>;
  setActiveTo: React.Dispatch<React.SetStateAction<Date | null>>;
  setCurrency: (value: string) => void;
  offerId: number;
  setEdit: (value: number | null) => void;
}

export default function EditOffers({
  offerName,
  payAmount,
  receiveAmount,
  offerType,
  activeFrom,
  activeTo,
  setOfferName,
  setOfferType,
  setPayAmount,
  setReceiveAmount,
  setActiveFrom,
  setActiveTo,
  currency,
  setCurrency,
  offerId,
  setEdit,
}: EditProps) {
  const [open, setOpen] = useState(false);

  function toggleOppen() {
    setOpen((open) => !open);
  }

  async function handleDelete(id: number) {
    const session_token = Cookies.get("c8_session_token");
    try {
      const res = await fetch(`${process.env.API_URL}offers`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.API_KEY as string,
        },
        body: JSON.stringify({
          offer_id: id,
          usersession: session_token,
        }),
      });
      if (res.ok) {
        window.location.reload();
        const data = await res.json();
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function handleEdit(id: number) {
    try {
      const session_token = Cookies.get("c8_session_token");
      const res = await fetch(`${process.env.API_URL}offer`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.API_KEY as string,
        },
        body: JSON.stringify({
          user_session: session_token,
          offer_data: {
            id: id,
            pay_amount: payAmount || null,
            receive_amount: receiveAmount || null,
            offer_type: offerType || null,
            offer_name: offerName || null,
            currency: currency || null,
          },
        }),
      });
      if (res.ok) {
        window.location.reload();
        const data = await res.json();
      }
      if (!res.ok) {
        throw new Error(`HTTP error! Status: ${res.status}`);
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:gap-2 w-full p-2 h-auto">
        <div>
          <label className="font-chakra text-[10px]" htmlFor="name">
            Offer Name:
          </label>
          <input
            type="text"
            id="name"
            placeholder="Offer Name"
            onChange={(e) => setOfferName(e.target.value)}
            className="text-main font-chakra h-[30px] w-full"
            value={offerName}
          />
        </div>

        <div>
          <label className="font-chakra text-[10px]" htmlFor="pay">
            Pay Amount:
          </label>
          <input
            id="pay"
            placeholder="Pay Amount"
            onChange={(e) => setPayAmount(e.target.value)}
            className="text-main font-chakra h-[30px] w-full"
            value={payAmount}
          />
        </div>

        <div>
          <label className="font-chakra text-[10px]" htmlFor="receive">
            Receive Amount:
          </label>
          <input
            id="receive"
            placeholder="Receive Amount"
            onChange={(e) => setReceiveAmount(e.target.value)}
            className="text-main font-chakra h-[30px] w-full"
            value={receiveAmount}
          />
        </div>

        <div className="w-[9.15em]">
          <label className="font-chakra text-[14px]" htmlFor="offers">
            Offer Type:
          </label>
          <div>
            <select
              className="h-[30px] text-main w-full"
              id="offers"
              onChange={(e) => setOfferType(e.target.value)}
            >
              <option value={offerType}>{offerType}</option>
              <option value="hard">HARD</option>
              <option value="soft">SOFT</option>
            </select>
          </div>
        </div>

        <div>
          <div className="w-[10em]">
            <label
              className="font-chakra text-start text-[14px]"
              htmlFor="from"
            >
              Active From:
            </label>
            <DayPicker
              startDate={activeFrom}
              setStartDate={setActiveFrom}
              setEndDate={setActiveFrom}
              className={"h-[30px] drop-down-font w-full"}
            />
          </div>

          <div className="w-[10em]">
            <label className="font-chakra text-[14px]" htmlFor="to">
              Active To:
            </label>
            <DayPicker
              startDate={activeTo}
              setEndDate={setActiveTo}
              setStartDate={setActiveTo}
              className={"h-[30px] drop-down-font w-full"}
            />
          </div>

          <div className="w-[9.15em]">
            <label className="font-chakra text-[14px]" htmlFor="currency">
              Currency:
            </label>
            <div>
              <select
                className="h-[30px] text-main w-full"
                id="currency"
                onChange={(e) => setCurrency(e.target.value)}
              >
                <option value={currency}>{currency}</option>
                <option value="INR">INR</option>
                <option value="CR8_RBY">CR8_RBY</option>
              </select>
            </div>
          </div>
        </div>
        <div>
          <div className="h-full ">
            <div className="h-full flex flex-row items-end justify-end gap-2 p-2 mt-2 lg-mt-0 lg:px-0">
              <div
                onClick={() => handleEdit(offerId)}
                className="bg-lime font-anton text-[14px] flex justify-center items-center hover:bg-limehover text-main w-full h-[32px] text-center cursor-pointer border-none"
              >
                Edit
              </div>
              <div
                onClick={() => setEdit(null)}
                className="border-2 font-anton text-[14px] flex justify-center items-center border-lime hover:border-limehover border-solid text-lime hover:text-limehover w-full h-[32px] text-center cursor-pointer"
              >
                Cancel
              </div>
              <div
                onClick={() => toggleOppen()}
                className="font-anton text-[14px] flex justify-center items-center bg-red text-white
                                     w-full h-[32px] text-center cursor-pointer"
              >
                Delete
              </div>
              <>
                {open && (
                  <AreYouShure
                    isOpen={toggleOppen}
                    onRequestClose={toggleOppen}
                    contentLabel="No Rubies"
                    children={
                      <div className="flex flex-col justify-center items-center p-4">
                        <div>
                          <p className="font-[600] font-anton text-white text-center text-[20px]">
                            Are You Shure You Want To Delete This Offer
                          </p>
                        </div>
                        <div className="flex flex-row gap-2 mt-4">
                          <div
                            className="border-2 font-anton text-[14px] flex justify-center items-center 
                                              border-lime hover:border-limehover border-solid text-lime 
                                              hover:text-limehover w-[120px] h-[32px] text-center cursor-pointer"
                            onClick={() => setEdit(null)}
                          >
                            Cancel
                          </div>
                          <div
                            className="font-anton text-[14px] flex justify-center items-center bg-red text-white
                                                w-[120px] h-[32px] text-center cursor-pointer"
                            onClick={() => handleDelete(offerId)}
                          >
                            Delete
                          </div>
                        </div>
                      </div>
                    }
                  />
                )}
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
